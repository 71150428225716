<template>
  <div class="subheader" v-if="currentRouteName !== '404'">
      <h1 class="subheader-title">
        <i class='subheader-icon' :class="icon"></i> {{ title || 'Trang tư vấn' }} <sup
          class='badge badge-primary fw-500' v-if="isNew">New</sup>
        <small>
        </small>
      </h1>
      <div v-if="listBtn && listBtn.length > 0" class="float-right">
        <button :key="btn.text" v-for="btn in listBtn" @click="btn.cb" :class="btn.class">
          {{btn.text}}
        </button>
      </div>
    <div class="subheader-block">
    </div>
  </div>
</template>

<script>
export default {
  name: "EdutalkTitle",
  props: {
    title: String,
    isNew: Boolean,
    icon: String,
    listBtn: Array,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>
