export const BIRTHDAY_TYPE = [
    {
        value: 1,
        classTag: 'green',
        messageTitle: 'NGƯỜI ĐỒNG ĐỘI ĐÁNG TIN CẬY',
        messageComment: '"Làm bất cứ điều gì nguyện bằng cả trái tim" chính là giá trị mà chúng tôi kỳ vọng ở bạn.',
        message: 'Bạn là một người đồng đội đáng tin cậy dù trong công việc hay trong cuộc sống, đồng hành cùng bạn là đồng hành cùng sức trẻ, lòng nhiệt huyết và nỗ lực không ngừng tiến về phía mục tiêu.' + "<br/>"+
            'Hãy trở thành phiên bản tốt nhất của bản thân mình, viết nên những chương mới trên Hải trình khát vọng cùng nhau nhé.',
        messageEnd: 'Tiến lên, tiếp tục tiến lên!'
    },
    {
        value: 2,
        classTag: 'orange',
        messageTitle: 'NGƯỜI DẪN DẮT',
        messageComment: '"Dám nhận sai, dám thay đổi"',
        message: 'Bạn đại diện cho thế hệ lãnh đạo kế cận xuất sắc của doanh nghiệp - ' + '<strong>Với năng lực và niềm tin vững chắc, dám nghĩ, dám làm, dám đặt ra những tiêu chuẩn mới.</strong>'+ "<br/>" + "<br/>" +
            'Thành quả hôm nay chính là khởi đầu cho những bước tiến xa hơn trên Hải trình khát vọng của chúng ta.',
        messageEnd: 'Tiếp tục nỗ lực tiến về phía trước bạn nhé!'
    },
    {
        value: 3,
        classTag: 'purple',
        messageTitle: 'NGƯỜI TRUYỀN LỬA',
        messageComment: '"Hy sinh là sự nghiệp cao cả nhất, chiến thắng là đóng góp vĩ đại nhất"',
        message: 'Bạn đại diện cho giá trị cảm hứng nhất của doanh nghiệp - '+ '<strong>Truyền động lực, truyền năng lượng, truyền kiến thức</strong>'+' cho những anh em đang cùng nhau miệt mài vì sứ mệnh giáo dục của chúng ta.'
            + "<br/>" +
            'Kiến thức trong đầu, niềm tin và nhiệt huyết trong tim, hãy tiếp tục viết nên chương mới trên Hải trình khát vọng cùng nhau nhé.',
        messageEnd: 'Tiến lên, tiếp tục tiến lên!'
    },

];
