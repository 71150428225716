<template>
    <nav id="js-primary-nav" class="primary-nav js-list-filter" role="navigation">
        <div class="nav-filter">
            <div class="position-relative">
                <input type="text" id="nav_filter_input" placeholder="Filter menu" class="form-control" tabindex="0">
                <a href="#" onclick="return false;" class="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                    <i class="fal fa-chevron-up"></i>
                </a>
            </div>
        </div>
        <div class="info-card">
            <img :src="currentUser.image ? currentUser.image : publicPath + 'media/default-avatar.png'" class="profile-image rounded-circle" alt="Dr. Codex Lantern">
            <div class="info-card-text">
                <a href="#" class="d-flex align-items-center text-white">
                    <span class="text-truncate text-truncate-sm d-inline-block">
                        {{ currentUser.name }} {{ selected }}
                    </span>
                </a>
                <span class="d-inline-block text-truncate text-truncate-sm">{{ currentUser.email }}</span>
            </div>
            <img :src="publicPath + 'media/card-backgrounds/cover-2-lg.png'" class="cover" alt="cover">
        </div>
        <ul id="js-nav-menu" class="nav-menu js-nav-built" style="margin-left: 0;">
            <template v-for="(menu, key) in Menus"  >
                <li :key="key" v-if="currentUser.permission.includes(menu.auth) || permissionForPosition(menu.position) || menu.auth === candidate || menu.name === 'honor'" >
                    <router-link :to="menu.url || ''" v-slot="{ href, navigate}">
                        <a v-if="menu.child && menu.child.length > 0"  href="javascript:void(0)" :data="href" :title="menu.title" @click="selected === key ? selected = null : selected = key" data-filter-tags="theme settings" class=" waves-effect waves-themed">
                            <i class="fal" :class="menu.icon"></i>
                            <span class="nav-link-text">{{ menu.title }}</span>
                            <b v-if="menu.child && menu.child.length > 0" class="collapse-sign"><em :class="{'fa-angle-up': selected === key, 'fa-angle-down': selected !== key}" class="fal "></em></b>
                        </a>
                        <a v-else  href="javascript:void(0)"  :title="menu.title" @click="customizeRedirect(href, navigate)" data-filter-tags="theme settings" >
                            <i class="fal" :class="menu.icon"></i>
                            <span class="nav-link-text">{{ menu.title }}</span>
                            <b v-if="menu.child && menu.child.length > 0" class="collapse-sign"><em :class="{'fa-angle-up': selected === key, 'fa-angle-down': selected !== key}" class="fal "></em></b>
                        </a>
                    </router-link>
                    <ul v-if="menu.child && menu.child.length > 0" :class="{ block : selected === key}">
                        <template v-for="(child_menu, k) in menu.child">

                            <li :key="k" :class="{ active: $route.name === child_menu.name }" @click="closeSidebar" v-if="currentUser.permission.includes(child_menu.auth) || permissionForPosition(child_menu.position) || child_menu.auth === 'all' || menu.auth === candidate">
                                <router-link :to="child_menu.url" v-slot="{ href, navigate }" v-if="child_menu.auth">
                                    <a :href="href"  :title="child_menu.title" @click="navigate" :data-filter-tags="child_menu.title">
                                        <span class="nav-link-text">{{ child_menu.title }} </span>
                                        <span class="dl-ref label bg-danger-500 ml-2"  v-if="child_menu.name === 'nhan-su-cho-duyet'">{{ getCountWaitUser }}</span>
                                    </a>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </nav>
</template>

<script>
    import {mapGetters} from "vuex";
    import {ROLE} from './../../../role.js';
    import {Menu} from "@/menu";

    export default {
        name: "EdutalkMenu",
        data() {
            return {
                publicPath: process.env.VUE_APP_BASE_URL,
                role: ROLE,
                Menus: Menu,
                selected: null,
                candidate: 'ung-vien',
            }
        },
        computed: {
            ...mapGetters(["currentUser","getCountWaitUser"]),
        },
        mounted() {
        },

        methods: {
            closeSidebar() {
                document.body.classList.remove("mobile-nav-on");
                let backdrop = document.querySelector(".hideBackDrop");
                if(!backdrop) {
                  return;
                }
                if (backdrop.classList.contains("add")) {
                    backdrop.classList.remove('add');
                    backdrop.classList.add('hide');
                }
            },
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            },
            customizeRedirect(href, navigate) {
                this.closeSidebar();
                if (this.$route.path !== href) this.$router.push(href)
            },
            permissionForPosition(position){
              return position && this.currentUser.user_positions.find((item) => {
                return position.includes(item.position);
              });
            },
        }
    };
</script>
