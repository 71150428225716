<template>
    <div class="page-wrapper" v-if="isAuthenticated">
        <!-- begin:: Header Mobile -->
        <edutalk-loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></edutalk-loader>
        <!-- end:: Header Mobile -->
        <div class="page-inner">
            <!-- begin:: Aside Left -->
            <edutalk-aside v-if="asideEnabled"></edutalk-aside>
            <!-- end:: Aside Left -->
            <div class="page-content-wrapper">
                <!-- begin:: Header -->
                <edutalk-header></edutalk-header>
                <!-- end:: Header -->
                <!-- begin:: Content -->
                <!-- the #js-page-content id is needed for some plugins to initialize -->
                <main id="js-page-content" role="main" class="page-content">
                    <!-- begin:: Content Head -->
                    <edutalk-breadcrumbs v-if="subheaderDisplay" v-bind:breadcrumbs="breadcrumbs"
                                         v-bind:title="pageTitle"></edutalk-breadcrumbs>
                    <edutalk-title v-bind:title="pageTitle" v-bind:icon="pageIcon"
                                   v-bind:listBtn="listBtn"></edutalk-title>
                    <!-- end:: Content Head -->
                    <router-view></router-view>
                </main>
                <edutalk-footer></edutalk-footer>
            </div>
        </div>
        <quick-menu></quick-menu>
      <check-list-test-input-modal v-if="openModalMention && isPilot"></check-list-test-input-modal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import EdutalkLoader from "@/views/content/edutalk-loader.vue";
import EdutalkAside from "@/views/layout/aside/edutalk-aside.vue";
import EdutalkHeader from "@/views/layout/header/edutalk-header.vue";
import EdutalkBreadcrumbs from "@/views/layout/header/edutalk-breadcrumbs.vue";
import EdutalkTitle from "@/views/layout/header/edutalk-title.vue";
import EdutalkFooter from "@/views/layout/footer/edutalk-footer.vue";
import QuickMenu from "@/views/layout/footer/edutalk-quick-menu.vue";
import mediumZoom from 'medium-zoom';
import {BIRTHDAY_TYPE} from "@/core/config/birthdayOption";
import confetti from "canvas-confetti";
import {SHOW_TYPE_BIRTH_DAY} from "@/core/services/store/user/users.module";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import SaleLevelUp from "../pages/sale/SaleLevelUp";
import CheckListTestInputModal from "./header/components/CheckListTestInputModal.vue";


export default {
    name: "Layout",
    components: {
      CheckListTestInputModal,
        SaleLevelUp,
        EdutalkLoader,
        EdutalkAside,
        EdutalkHeader,
        EdutalkBreadcrumbs,
        EdutalkTitle,
        EdutalkFooter,
        QuickMenu
    },
    beforeMount() {
        // show page loading
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },
    data() {
        return {
            version: '',
            publicPath: process.env.VUE_APP_BASE_URL,
            end: Date.now() + (2 * 1000),
            zoomDesktop: '',
            zoomMobile: '',
            userBirthDay: {},
            BIRTHDAY_TYPE
        };
    },
    created() {
        // this.getTypeBirthday();
    },
    mounted() {
        if (!this.isAuthenticated) {
            this.$router.push({name: "login"});
        } else {
            if (this.currentUser && this.currentUser.is_confirm !== 2 && this.currentUser.is_confirm !== 4) {
                this.$router.push({name: 'update-user', params: {id: this.currentUser.id}});
            }
        }
        // Simulate the delay page loading
        setTimeout(() => {
            // Remove page loader after some time
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }, 1000);
        // begin birthday
        let pageWrapper = document.querySelector("body");
        this.initZoomScroll(pageWrapper);
        this.initZoomScrollMobile(pageWrapper);
    },
    methods: {
        getTypeBirthday(){
            let ds = document.querySelector("body")
            if (this.currentUser) {
                this.showTypeUserBirthDay(ds.clientWidth > 425);
            }
        },
        initZoomScroll(pageWrapper) {
            this.zoomDesktop = mediumZoom('#zoom-scrollOffset', { scrollOffset: 0, background: 'rgba(25, 18, 25, .7)'})
            this.zoomDesktop.on('close', () => {
                pageWrapper.style.overflow = 'auto';
                this.closeDialog();
            })
            this.zoomDesktop.on('open', () => {
                window.scrollTo(0, 0);
                pageWrapper.style.overflow = 'hidden';
            })
        },
        initZoomScrollMobile(pageWrapper) {
            this.zoomMobile = mediumZoom('#zoom-scrollOffsetMobile', { scrollOffset: 0, background: 'rgba(25, 18, 25, .7)'})
            this.zoomMobile.on('close', () => {
                pageWrapper.style.overflow = 'auto';
                this.closeDialog();
            })
            this.zoomMobile.on('open', () => {
                window.scrollTo(0, 0);
                pageWrapper.style.overflow = 'hidden';
            })
        },
        showTypeUserBirthDay(type) {
            this.$store.dispatch(SHOW_TYPE_BIRTH_DAY, this.currentUser.id).then((res) => {
                let userRes = res;
                this.userBirthDay.length_work = userRes.length_work;
                if(this.userBirthDay.length_work !== 0) {
                this.userBirthDay = this.BIRTHDAY_TYPE.find(item => item.value === userRes.type);
                if (type) {
                    this.zoomDesktop.open();
                } else {
                    this.zoomMobile.open();
                }
                let backdrop = document.querySelector(".birthdayBackDrop");
                backdrop.classList.remove('hide');
                backdrop.classList.add('add');
                let body_scroll = document.querySelector("body");
                body_scroll.classList.add('over-none');
                this.frame();
                }
            }).catch((err) => {
                console.log(err);
            })
        },
        closeAside: function (event) {
            if (document.body.classList.contains("mobile-nav-on")) {
                document.body.classList.remove("mobile-nav-on");
            }
            let backdrop = document.querySelector(".hideBackDrop");
            if (backdrop.classList.contains("add")) {
                backdrop.classList.remove('add');
                backdrop.classList.add('hide');
            }
            let backdropDes = document.querySelector(".birthdayBackDrop");
            if (backdropDes.classList.contains("add")) {
                backdropDes.classList.remove('add');
                backdropDes.classList.add('hide');
                this.resetFrame();
            }
        },
        closeDialog(e) {
            if (!e || !e.target.closest('.content')) {
                this.zoomDesktop.close()
                this.zoomMobile.close()
                let event = JSON.parse(localStorage.event);
                event.status = 1
                localStorage.event = JSON.stringify(event);
                let backdrop = document.querySelector(".birthdayBackDrop");
                if (backdrop.classList.contains("add")) {
                    backdrop.classList.remove('add');
                    backdrop.classList.add('hide');
                    this.resetFrame();
                }
                let body_scroll = document.querySelector("body");
                body_scroll.classList.remove('over-none');
            }
        },
        closeDialogBtn() {
            this.zoomDesktop.close()
            this.zoomMobile.close()
            let backdrop = document.querySelector(".birthdayBackDrop");
            if (backdrop.classList.contains("add")) {
                backdrop.classList.remove('add');
                backdrop.classList.add('hide');
                this.resetFrame();
            }
            let body_scroll = document.querySelector("body");
            body_scroll.classList.remove('over-none');
        },

        frame() {
            this.end = Date.now() + (2 * 1000)
            this.frameConfetti();
        },
        frameConfetti() {
            confetti({
                particleCount: 3,
                angle: 60,
                spread: 55,
                origin: {x: 0},
                colors: this.colors,
            });
            confetti({
                particleCount: 3,
                angle: 120,
                spread: 55,
                origin: {x: 1},
                colors: this.colors,
            });
            if (Date.now() < this.end) {
                requestAnimationFrame(this.frameConfetti);
            }
        },
        resetFrame() {
            confetti.reset();
        },
    },
    computed: {
        ...mapGetters([
            "isAuthenticated",
            "breadcrumbs",
            "pageTitle",
            "pageIcon",
            "layoutConfig",
            "currentUser",
            "listBtn",
            "openModalMention",
            "isPilot"
        ]),

        /**
         * Check if the page loader is enabled
         * @returns {boolean}
         */
        loaderEnabled() {
            return !/false/.test(this.layoutConfig("loader.type"));
        },

        /**
         * Check if container width is fluid
         * @returns {boolean}
         */
        contentFluid() {
            return this.layoutConfig("content.width") === "fluid";
        },

        /**
         * Page loader logo image using require() function
         * @returns {string}
         */
        loaderLogo() {
            return process.env.BASE_URL + this.layoutConfig("loader.logo");
        },

        /**
         * Check if the left aside menu is enabled
         * @returns {boolean}
         */
        asideEnabled() {
            return !!this.layoutConfig("aside.self.display");
        },

        /**
         * Set the right toolbar display
         * @returns {boolean}
         */
        toolbarDisplay() {
            // return !!this.layoutConfig("toolbar.display");
            return true;
        },

        /**
         * Set the subheader display
         * @returns {boolean}
         */
        subheaderDisplay() {
            return !!this.layoutConfig("subheader.display");
        }
    }
};
</script>
