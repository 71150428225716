<template>
    <div class="h-auto">

    </div>
</template>

<script>
    export default {
        name: "EdutalkCalendar",
        props: {
            event: []
        },
        data() {
            return {
            }
        },
        mounted() {
        }
    };
</script>
