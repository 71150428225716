import {PERMISSION} from "./permission";
import {TPOL, TPNS, TVV, TRAINER, POSITION} from "@/core/config/accountTypeOption";

export const Menu = [
    {
        "url": "/work-calendar",
        "title": "Đăng ký lịch làm",
        "icon": "fa-calendar-alt",
        "auth": PERMISSION.DANG_KI_LAM,
        "name": "work-calendar",
    },
    {
        "title": "Chấm công",
        "icon": "fa-calendar-check",
        "auth": PERMISSION.CHAM_CONG,
        "position": TPOL,
        "child": [
            {
                "url": "/attendance-calendar",
                "name": "attendance-calendar",
                "title": "Chấm công",
                "position": TPOL,
                "auth": PERMISSION.CHAM_CONG_NHAN_SU,
            },
            {
                "url": "/attendance-view",
                "name": "attendance-view",
                "title": "Xem chấm công nhân sự",
                "position": TPOL,
                "auth": PERMISSION.XEM_CHAM_CONG,
            }
        ]
    },
    // Danh sách
    {
        "title": "Danh sách",
        "icon": "fa-list-ul",
        "auth": PERMISSION.DANH_SACH,
        "position": POSITION,
        "child": [
            {
                "url": "/payment-list",
                "name": "payment-list",
                "title": "Danh sách đóng tiền",
                "auth": PERMISSION.DONG_TIEN_THEO_DOI_KD,
            },
            {
                "url": "/payment-location",
                "name": "payment-location",
                "title": "Đóng tiền tại điểm",
                "auth": PERMISSION.DONG_TIEN_TAI_DIEM,
            },
            {
                "url": "/hierarchy",
                "name": "hierarchy",
                "title": "Phân cấp nhân sự",
                "auth": PERMISSION.PHAN_CAP_NHAN_SU,
            },
            {
                "url": "/customer/list",
                "name": "customer-list",
                "title": "Khách hàng",
                "auth": PERMISSION.KHACH_HANG,
            },
            {
                "url": "/nhan-su-hoat-dong",
                "name": "nhan-su-hoat-dong",
                "title": "Nhân sự hoạt động",
                "position": TPNS,
                "auth": PERMISSION.NHAN_SU_HOAT_DONG,
            },
            {
                "url": "/nhan-su-cho-duyet",
                "name": "nhan-su-cho-duyet",
                "title": "Nhân sự chờ xét duyệt",
                "position": TPNS,
                "auth": PERMISSION.NHAN_SU_CHO_XET_DUYET,
            },
        ]
    },
    // Học tập
    {
        "title": "Quản lý đào tạo",
        "icon": "fa-info-square",
        "position": TRAINER,
        "auth": PERMISSION.NHAN_SU_CHO_XET_DUYET,
        "child": [
            {
                "url": "/danh-sach-ung-vien",
                "name": "danh-sach-ung-vien",
                "position": TRAINER,
                "title": "Chương trình đào tạo hội nhập",
                "auth": PERMISSION.NHAN_SU_CHO_XET_DUYET,
            },
            {
                "url": "/training-manage",
                "name": "training-manage",
                "title": "Chương trình TVV định kỳ",
                "auth": PERMISSION.NHAN_SU_CHO_XET_DUYET,
            },
            {
                "url": "/manage-list-course",
                "name": "manage-list-course",
                "title": "Báo cáo học tập",
                "auth": PERMISSION.BAO_CAO_HOC_TAP,
            },
        ]
    },
    // Hành chính
    {
        "title": "Hành chính",
        "icon": "fa-money-bill",
        "auth": PERMISSION.HANH_CHINH,
        "child": [
            {
                "url": "/phieu-luong",
                "title": "Phiếu lương",
                "name": "phieu-luong",
                "auth": PERMISSION.PHIEU_LUONG,
            },
            {
                "url": "/cham-cong",
                "title": "Chấm công",
                "name": "cham-cong",
                "auth": PERMISSION.CHAM_CONG_VAN_TAY,
            },
            {
                "url": "/thu-nhap-van-phong",
                "title": "Văn phòng",
                "name": "thu-nhap-van-phong",
                "auth": PERMISSION.THU_NHAP_VAN_PHONG,
            },
            {
                "url": "/propose",
                "name": "list-propose-application",
                "title": "Đề xuất",
                "auth": PERMISSION.DUYET_DON,
            },
            {
                "url": "/propose/decided/teamLead-BOD",
                "name": "list-propose-lead-bod",
                "title": "Duyệt đề xuất",
                "auth": PERMISSION.DUYET_DE_XUAT,
            },
        ]
    },
    //Tư vấn
        {
            "title": "Tư vấn",
            "icon": "fa-headphones",
            "position": TVV,
            "auth": PERMISSION.TU_VAN,
            "child": [
                {
                    "url": "/advise/list-customer-tvv",
                    "title": "Khách hàng tư vấn",
                    "name": "list-customer-tvv",
                    "auth" : PERMISSION.KHACH_HANG_TU_VAN,
                    "position": TVV,
                },
                {
                    "url": "/advise/doanh-so-tu-van",
                    "title": "Doanh số tư vấn",
                    "name": "doanh-so-tu-van",
                    "auth" : PERMISSION.DOANH_SO_TU_VAN,
                    "position": TVV,
                },
                {
                    "url": "/contract",
                    "title": "Hợp đồng tư vấn",
                    "name": "hop-dong-tu-van",
                    "auth" : PERMISSION.DOANH_SO_TU_VAN,
                    "position": TVV,
                },
                {
                    "url": "/schedule_duty",
                    "title": "Đăng ký lịch trực",
                    "name": "schedule_duty",
                    "auth" : PERMISSION.DANG_KI_LICH_TRUC,
                    "position": TVV,
                },
                {
                    "url": "/lich-tuvan",
                    "title": "Lịch tư vấn",
                    "auth" : PERMISSION.LICH_TU_VAN,
                    "position": TVV,
                    "name": "lich_tuvan",
                }
            ]
        },
    //Báo cáo

    {
        "title": "Báo cáo",
        "icon": "fa-chart-area",
        "auth": PERMISSION.BAO_CAO,
        "child": [
            {
                "url": "/report/human-resource",
                "name": "nhan-su-trong-thang",
                "title": "Nhân sự",
                "position": TPNS,
                "auth": PERMISSION.NHAN_SU_TRONG_THANG,
            },
            {
                "url": "/report/kinh-doanh-doi",
                "title": "Kinh doanh Đội",
                "name": "kinh-doanh-doi",
                "auth": PERMISSION.KINH_DOANH_DOI,
            },
            {
                "url": "/report/kinh-doanh-van-phong",
                "title": "Kinh doanh Văn phòng",
                "name": "kinh-doanh-van-phong",
                "auth": PERMISSION.KINH_DOANH_VAN_PHONG,
            },
            {
                "url": "/tai-chinh/bao-cao-van-phong",
                "title": "Báo cáo văn phòng",
                "name": "bao-cao-van-phong",
                "auth": PERMISSION.BAO_CAO_VAN_PHONG,
            },
            {
                "url": "/tai-chinh/bao-cao-khu-vuc",
                "title": "Báo cáo khu vực",
                "name": "bao-cao-khu-vuc",
                "auth": PERMISSION.BAO_CAO_KHU_VUC,
            },
            {
                "url": "/tai-chinh/bao-cao-vung",
                "title": "Báo cáo vùng",
                "name": "bao-cao-vung",
                "auth": PERMISSION.BAO_CAO_VUNG,
            },
            {
                "url": "/tai-chinh/toc-do-tang-truong",
                "title": "Tốc độ tăng trưởng",
                "name": "toc-do-tang-truong",
                "auth": PERMISSION.TOC_DO_TANG_TRUONG_KHU_VUC,
            },
            {
                "url": "/tai-chinh/toc-do-tang-truong-doanh-so-khu-vuc",
                "title": "Tốc độ tăng trưởng doanh số khu vưc",
                "name": "toc-do-tang-truong-doanh-so-khu-vuc",
                "auth": PERMISSION.TOC_DO_TANG_TRUONG_DOANH_SO_KHU_VUC,
            },
        ]
    },
    //Thông tin
    //Báo cáo
    {
        "title": "Thông tin",
        "icon": "fa-info-square",
        "auth": PERMISSION.THONG_TIN,
        "child": [
            // {
            //     "url": "/thong-tin/he-thong",
            //     "name": "information-system",
            //     "title": "Hệ thống thông tin",
            //     "auth": PERMISSION.HE_THONG_THONG_TIN,
            // },
            {
                "url": "/thong-tin/thong-bao",
                "name": "information-notification",
                "title": "Thông báo",
                "auth": PERMISSION.THONG_BAO_TU_EDUTALK,
            },
            {
                "url": "/thong-tin/chinh-sach-nhan-su",
                "name": "information-chinh-sach",
                "title": "Chính sách nhân sự",
                "auth": PERMISSION.CHINH_SACH_NHAN_SU,
            },
            {
                "url": "/thong-tin/cam-nang-van-hanh",
                "name": "information-cam-nang",
                "title": "Cẩm nang vận hành",
                "auth": PERMISSION.CAM_NANG_VAN_HANH,
            },
            {
                "url": "/advise/center",
                "name": "advise-center",
                "title": "Thông tin sản phẩm",
                "auth": 'all',
            }
        ]
    },
    // Học tập
    {
        "title": "Học tập",
        "icon": "fa-info-square",
        "auth": PERMISSION.CANDIDATE,
        "child": [
            {
                "url": "/quantum-leap-intro",
                "name": "information-notification",
                "title": "Giới thiệu Quantum Leap",
                "auth": PERMISSION.CANDIDATE,
            },
            {
                "url": "/list-course",
                "name": "information-notification",
                "title": "Danh sách khóa học",
                "auth": PERMISSION.CANDIDATE,
            },
        ]
    },
    {
        "title": "Vinh danh",
        "icon": "vinh-danh",
        "name": "honor",
        "url": "/honors-page",
        "auth": ""
    },
];
