<template>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from 'sweetalert2'
import {SALE_CHECK_HAPPY_LEVEL_UP, SALE_UPDATE_HAPPY_LEVEL_UP} from "../../../core/services/store/profile.module";
import {SET_PROFILE_HAPPY_LEVEL_UP} from "../../../core/services/store/auth.module";

export default {
  name: "SaleLevelUp",
  data() {
    return {
      user: null
    }
  },
  mounted() {
    this.checkHappyLevelUp();
  },
  methods: {
    checkHappyLevelUp() {
      this.$store.dispatch(SALE_CHECK_HAPPY_LEVEL_UP, {
        id: this.currentUser.id,
      }).then((data) => {
        this.user = data.data;
        this.verify();
      })
    },
    verify() {
      if (!this.user) {
        return;
      }
      let profile = this.user.profile;
      if (!profile.happy_level_up || profile.account_type_id != 3) {
        return;
      }

      this.message();
      this.updateHappyLevelUp();
    },
    message() {
      Swal.fire({
        // icon: 'success',
        title: 'Thông báo',
        html: '<div class="sale-level-up">' +
            '<p style="color: #0dad0d">Bạn đã vượt qua kỳ thử việc!</p>' +
            '<p><span>Vị trí mới:</span> <span style="color: #6e4e9e">Nhân viên kinh doanh</span></p>' +
            '<p><span class="level">Cấp bậc:</span> <span style="color: #6e4e9e">Junior</span></p>' +
            '</div>'
      })
    },
    updateHappyLevelUp() {
      this.$store.dispatch(SALE_UPDATE_HAPPY_LEVEL_UP, {
        id: this.currentUser.id,
        happy_level_up: 0
      });
    }
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),
  }
}
</script>

<style lang="scss">
.sale-level-up {
  text-align: start !important;

  .level {
    margin-top: 3px !important;
  }
}
</style>